import React from 'react';
import './Botton.css'
import Button from '@mui/material/Button';
const Botton = ()=>{
    return(

        
        <div class='center_botton'>
             <div class='botton_fetrue'>
            <br></br>
        <Button className='botton1' style={{backgroundColor:"#333", width:"200px",borderRadius: '5%' ,color:"white"}}  color="success" href="https://www.google.com/maps/place/R.R.S.+collections/@27.7044822,76.1860378,15z/data=!4m7!3m6!1s0x396d45401d1ff1c9:0x10f223083670a018!8m2!3d27.7049537!4d76.2002081!15sCk5SLlIuUy4gY29sbGVjdGlvbnMsIHB1cmFuaSBzYWJqaSBtYW5hZGkgbWVuIG1hcmtldCwgS290cHV0bGksIFJhamFzdGhhbiAzMDMxMDhaSiJIcnJzIGNvbGxlY3Rpb25zIHB1cmFuaSBzYWJqaSBtYW5hZGkgbWVuIG1hcmtldCBrb3RwdXRsaSByYWphc3RoYW4gMzAzMTA4kgEPc2hvcHBpbmdfY2VudGVy4AEA!16s%2Fg%2F11gxx7110h">
        Address
        </Button>
            </div>
             <div class='botton_fetrue'>
            <br></br>
        <Button className='botton1' style={{backgroundColor:"#333", width:"200px",borderRadius: '5%', color:"white"}}   href="tel:+919772724040">
         Contact
        
        </Button>
            </div>
            <div class='botton_fetrue'></div>
            <div class='botton_fetrue'>
            <br></br>
        <Button className='botton1' style={{backgroundColor:"#333", width:"200px",borderRadius: '5%' ,color:"white"}}   href="https://api.whatsapp.com/send?phone=919772724040">
         whatsapp
        </Button>
            </div>
           
            <div class='botton_fetrue'>
            <br></br>
        <Button className='botton1' style={{backgroundColor:"#333", width:"200px" ,borderRadius: '5%', color:"white"}}   href="https://www.instagram.com/rrscollectionskotputli/">
         Instagram
        </Button>
            </div>
            <div class='botton_fetrue'>
            <br></br>
        <Button className='botton1' style={{backgroundColor:"#333", width:"200px", borderRadius: '5%', color:"white"}}   href="https://www.facebook.com/rrscollectionskotputli">
         Facebook
        </Button>
            </div>

            <div class='botton_fetrue'>
            <br></br>
        <Button className='botton1' style={{backgroundColor:"#333", width:"200px", borderRadius: '5%', color:"white"}}   href="mailto:rrscollectionsofficial@gmail.com?,&subject=Mail from Website&body=Enter your enquiry">
         Gmail
        </Button>
            </div>
            
        
        </div>
        
    )
}

export default Botton;