import React from 'react'
import './Nav.css'
import logo from './logo.jpg'
//import Image from 'react-bootstrap/Image'
const Navbar = ()=>{
    return(
        <div class='center'>
             
              <img class="enlarge" src={logo} alt="logo"/> 
            {/*<Image class="enlarge"  src={logo} ></Image> */} 
             <br></br>
             <h2 className='h3 font-weight-bold ' >R.R.S. Collections</h2>
             <p className='fs-6'>
             Wear Good - Look Good <br></br>

             We provide you the latest style, fashionable, branded, and trendy clothes. 
             <br>
             </br>
             We deal in Sarees, suites, lenghas, Rajputi Poshakh,suiting Shirting, Girls wear, Kids-wear and Gents wear Garments.
             </p>
             {/* <p className='fs-5'>
             We deal in Sarees, suites, lenghas, Rajputi Poshakh, Suiting Santing and Garments.
             </p> */}
        </div>
    )
}
export default Navbar;