import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Item from "./Item";
import slider from "./slider.json"
//import { Paper, Button } from '@mui/material'

function Example()
{

    return (
        <Carousel  duration={200} interval={3000} >
            {
                slider.map( item => <Item key={item.id} item={item} /> )
            }
        </Carousel>
    )
}
export default Example