import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Navbar  from './components/Navbar';
import Botton from './components/Botton';
import Footer from './components/Footer';
//import Exmp from './components/exmp'
import Carousel from './components/Carousel';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

function shop() {
  return (
    <>
    <div class='main' > 
       <Navbar></Navbar>
       <Carousel></Carousel>
       <Botton></Botton>
       <Footer></Footer>

       </div>
      
      </>
      );
      };
      
      export default shop;
