import React from 'react'
// import { FaFacebook, FaInstagram, FaMapMarkerAlt, FaWhatsapp } from 'react-icons/fa';
// import { AiOutlineMail } from "react-icons/ai";
import './footer.css';
export default  function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div >
          <footer className="footer" >
            <div className='address'>
              <p><b>Address: </b>Baberwal Tower, purani sabji mandi, main baazar, Kotputli, Rajasthan 303108.</p>
            </div>
      {/* <div className="footer-social-media text-center">
        <a  href="mailto:rrscollectionsofficial@gmail.com?,&subject=Mail from Website&body=Enter your enquiry" target="_blank" rel="noopener noreferrer"><AiOutlineMail /></a>
        <a href="https://www.facebook.com/rrscollectionskotputli/" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
        <a href="https://www.instagram.com/rrscollectionskotputli/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        <a href="https://www.google.com/maps/place/R.R.S.+collections/@27.7044822,76.1860378,15z/data=!4m7!3m6!1s0x396d45401d1ff1c9:0x10f223083670a018!8m2!3d27.7049537!4d76.2002081!15sCk5SLlIuUy4gY29sbGVjdGlvbnMsIHB1cmFuaSBzYWJqaSBtYW5hZGkgbWVuIG1hcmtldCwgS290cHV0bGksIFJhamFzdGhhbiAzMDMxMDhaSiJIcnJzIGNvbGxlY3Rpb25zIHB1cmFuaSBzYWJqaSBtYW5hZGkgbWVuIG1hcmtldCBrb3RwdXRsaSByYWphc3RoYW4gMzAzMTA4kgEPc2hvcHBpbmdfY2VudGVy4AEA!16s%2Fg%2F11gxx7110h" target="_blank" rel="noopener noreferrer"><FaMapMarkerAlt /></a>
        <a href="https://api.whatsapp.com/send?phone=919772724040" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
       
      </div> */}

      <div className="footer-line"></div>
      <div className="footer-copyright" >
       <a href='https://www.instagram.com/yourdigitalbuddies/' target="_blank" rel="noopener noreferrer" style={{color:'white' , fontSize:"14px"}} >&copy; {currentYear} YourDigitalBuddies. All rights reserved.</a> 
      </div>
    </footer>
    </div>
  )
}

