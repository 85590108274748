import React from 'react';
//import "./item.css"
//import { Paper} from '@mui/material'


function Item({item})
{
    
    const img =  item.image;
    console.log(img);
    return (
        <div className='paper' key={item.id}>
            
            <div className='cero' style={{display:'flex' , justifyContent: 'center' , alignItems:'center' , flexDirection:'column'}}> 
            {/* <img src={item.image} /> */}
            <img  src={item.image} alt={item.title} style={{width:"auto", height:"55vh" ,alignItems:'center' ,borderRadius:'5%' , boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}/>
           {/*  <p style={{textEmphasisColor:"#212121"}}>{item.title}</p> */}
            
            </div>
         
        </div>
    )
}

export default Item